<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>The Point Systems</v-toolbar-title>
          </v-toolbar>
          <v-tabs
            fixed-tabs
            v-model="activeTab"
            background-color="color2"
            slider-color="color3"
            slider-size="3"
          >
            <v-tab
              exact
              ripple
              class="color2Text--text"
              v-for="ps in pointSystems"
              :key="ps.id"
              :to="`/points/${ps.id}`"
            >
              {{ps.name}}
            </v-tab>
            <v-tab exact ripple class="color2Text--text" to="/points/aau">
              AAU
            </v-tab>
            <v-tab exact ripple class="color2Text--text" @click.stop="popAvp">
              AVP/USAV
            </v-tab>
            <v-tab exact ripple class="color2Text--text" to="/points/bvne">
              BVNE
            </v-tab>
            <v-tab exact ripple class="color2Text--text noTransform" to="/points/p1440">
              p1440
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="activeTab"
          >
            <v-tab-item
              v-for="ps in pointSystems"
              :key="ps.id"
              :value="`/points/${ps.id}`"
            >
              <router-view :is-tab="true" v-if="activeTab === `/points/${ps.id}`"></router-view>
            </v-tab-item>
            <v-tab-item
              value="/points/aau"
            >
              <router-view :is-tab="true" v-if="activeTab === '/points/aau'"></router-view>
            </v-tab-item>
            <v-tab-item
              value="/points/bvne"
            >
              <router-view :is-tab="true" v-if="activeTab === '/points/bvne'"></router-view>
            </v-tab-item>
            <v-tab-item
              value="/points/p1440"
            >
              <router-view :is-tab="true" v-if="activeTab === '/points/p1440'"></router-view>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      activeTab: '/points/aau'
    }
  },
  computed: {
    ...mapGetters(['theme']),
    pointSystems () {
      const systems = this.theme.pointSystems
      return systems.sort(firstBy('isDefaut'))
    }
  },
  methods: {
    popAvp () {
      window.open('https://vblimages.blob.core.windows.net/files/9badbd4c-3ba2-44fb-b053-a92cc9e397d8.pdf', '_blank')
    }
  },
  watch: {
    activeTab: function (n, o) {
      if (!`${n}`.startsWith('/points')) {
        this.$nextTick(() => {
          this.activeTab = o
        })
      }
    }
  },
  mounted () {
    if (this.$route.name === 'points') {
      if (this.pointSystems.length) {
        this.$router.replace(`/points/${this.pointSystems[0].id}`)
      } else {
        this.$router.replace('/points/aau')
      }
    }
  }
}
</script>

<style>
.noTransform {
  text-transform: none !important
}
</style>
